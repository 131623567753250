.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  max-width: 1024px;
  gap: 10px;
}
