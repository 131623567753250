.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
  flex: auto;
}

.root-container {
  min-height: 100vh !important;
}

.logo {
  float: left;
  height: 30px;
  margin: 16px 24px 16px 0;
}

/* upload file list */
.ant-upload-list {
  height: 100px;
  overflow-y: scroll;
}

.clickable {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .dicom-card > .ant-card-body {
    padding: 24px 0 !important;
  }

  .ant-layout-header {
    padding: 0 20px !important;
  }
}
