.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  max-width: 512px;
  padding: 24px;
}
