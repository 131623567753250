.section__wrapper {
  width: 100%;
  position: relative;
}

.main__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.banner {
  width: 100%;
}

.banner__content__container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
}

.banner__content__half {
  width: 50%;
  flex-grow: 1;
}

.banner__content {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  padding: 20px;
}

.banner__content h1 {
  font-size: clamp(50px, 10vw, 70px) !important;
  margin-bottom: 14px;
  color: #fff;
}

.banner__content p {
  color: #f8fafc !important;
  /* line-height: clamp(20px, 3vw, 40px); */
  font-size: clamp(20px, 3vw, 40px) !important;
  font-weight: 500;
}

.firstview__headline__wrapper {
  transform: translate(0px, -50%);
  top: 50%;
  position: relative;
  left: 10%;
  width: fit-content
}

.firstview__headline {
  font-size: 65px;
  font-size: clamp(30px, 3.5vw, 70px);
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: -1.95px;
  text-align: left;
  color: #fff;
}

.firstview__buttonwrapper {
  width: 50%;
  margin-top: 45px;
  display: flex;
  gap: 10px;
}
