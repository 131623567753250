.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

@media only screen and (max-width: 600px) {
  .section {
    padding: 10px 0;
  }  
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  max-width: 1600px;
  gap: 10px;
}
